/* You can add global styles to this file, and also import other style files */
body {
  font-family: Arial;
}

p, label {
  margin: 10px 0px 10px 0px;
}

.flex {
  display: flex;
}

.fullwidth {
  width: 100%;
}

.borderline {
  border-bottom: 1px solid #000;
}

.menu {
  width: 225px;
  border-right: 1px solid #000;
}

.content {
  padding-left: 50px;
}

.fullflex {
  flex: 1;
}

.centered {
  margin: auto;
}

.button {
  cursor: pointer;
}

.mandatoryField::before {
  content: '* ';
}

.tinyInput {
  width: 60px !important;
}

.invalidInput {
  border: 1px solid red;
}